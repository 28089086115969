export const auth = {
    signIn: () => '/auth/sign-in',
    roles: () => '/auth/roles',
    resetPassword: () => '/auth/reset',
    updatePassword: () => '/auth/password',
    checkToken: (token) => `/auth/check-token?token=${token}`,
    resetUser: () => '/auth/reset-user',
    resetClient: () => '/auth/reset-client',
    clearCookies: () => '/auth/clear-cookies',
    forgotPassword: () => '/auth/forgot-password',
    checkRole: () => '/auth/checkRole',
    changeCompany: () => `/auth/changecompany`,
};

export const companies = {
    getOffices: (companyId) => `/companies/${companyId}/offices`,
    getSupervisors: (companyId) => `/companies/${companyId}/supervisors`,
    getClients: (companyId) => `/companies/${companyId}/clients`,
    getClinicians: (companyId) => `/companies/${companyId}/clinicians`,
    getServiceCodes: (companyId) => `/companies/${companyId}/servicecodes`,
    getCompanyInfo: () => `/companies/info`,
    // getAllCompanies: (isdropdown) => `/company/getAllCompanies?isdropdown=${isdropdown}`,
    getAllCompanies: () => `/company/getAllCompanies`,
    getCompanyDetails: (companyId) => `/company/getCompany/${companyId}`,
    updateCompanyDetails: (companyId) => `/company/updateCompany/${companyId}`,
    createCompany: () => `/company/create-company`,
    getDefaultAssetsList: () => `/company/getDefaultAssetsList`,
    setActiveStatusCompany: (id) => `/company/setActivateStatusCompany/${id}`,
    uploadAsset: (id) => `/company/uploadAsset/${id}`,
    getAssetList: (id) => `/company/getAssetList/${id}`,
    deleteAsset: (company_id) => `/company/asset/${company_id}`,
    changeActiveStatus: (company_id) => `/company/changeactivestatus/${company_id}`,
    deleteUnSaved: (company_id) => `/company/deleteInActive/${company_id}`,
    getSavedAssetList: (company_id) => `company/getSavedAssetList/${company_id}`,
    getTags: (company_id) => `company/getTags/${company_id}`,
};

export const users = {
    getUserDetails: (id) => `/users/${id}`,
    getUsers: () => `/users/`,
    getActiveUsers: () => '/users/list/active-users',
    createNewUser: () => `/users`,
    getHeaderDetails: (id) => `/users/${id}/header`,
    updateUser: (id) => `/users/${id}`,
    canSign: (id) => `/users/userCanSign/${id}`,
    menu: () => '/users/menu',
    serviceCoordinators: () => '/users/service-coordinators',
    getStarkidsClinician: () => '/users/clinician-with-starkid-tag',
};

export const counselors = {
    getCounselors: () => `/clients/get-counselors`,
};

/**
 * Get file by id
 */
export const file = {
    getFile: (id) => `/file/${id}`,
    getMultipleCompaniesFiles: (id, companyId) => `/file/${id}/${companyId}`,
    getProfilePhoto: (id) => `/file/${id}`,
    getList: () => '/file/',
    getFileByKind: () => '/file/get-by-kind',
    saveFile: () => '/file/save',
    getQuickList: (clientId) => `/file/quick-list?c=${clientId}`,
    updateFile: (fileId) => `/file/${fileId}`,
    deleteFile: (fileId) => `/file/${fileId}`, // external files only
};

/**
 * Upload
 */
export const upload = {
    upload: () => `/file/create`,
};

export const invoices = {
    getInvoice: (invoiceId) => `/invoices/${invoiceId}`,
    getList: () => '/invoices/by-client',
    getInvoices: (clientId, page, limit, search) =>
        `/invoices?c=${clientId}${page ? `&p=${page}` : '&p=1'}${limit ? `&l=${limit}` : '&l=10'}${
            search ? `&s=${search}` : ''
        }`,
    getAmountOwed: (invoiceId) => `/invoices/${invoiceId}/amount-owed`,
    createInvoices: () => '/invoices',
    payWithBalance: () => `/invoices/pay-from-balance`,
    directPayment: () => '/invoices/pay-direct',
    generateSuperbill: (clientId, startDate, endDate) =>
        `/invoices/superbill?s=${startDate}&e=${endDate}&c=${clientId}`,
    getSuperbillPdf: (clientId, startDate, endDate) =>
        `/invoices/superbill/pdf?s=${startDate}&e=${endDate}&c=${clientId}`,
    saveSuperbillPdf: (clientId, startDate, endDate) =>
        `/invoices/superbill/pdf?s=${startDate}&e=${endDate}&c=${clientId}`,
    writeOff: () => '/invoices/write-off',
    getQuickList: (clientId, filter) => `/invoices/quick?c=${clientId}&f=${filter}`,
    getPdf: (invoiceId) => `/invoices/${invoiceId}/pdf`,
    saveAsPdf: (invoiceId) => `/invoices/${invoiceId}/pdf`,
    createManualInvoice: () => '/invoices/manual',
};

export const ledger = {
    getClientBalance: (clientId) => `/ledger/balance?c=${clientId}`,
    getTransactionQuickList: (clientId) => `/ledger/quick-transactions?c=${clientId}`,
    getStatement: (clientId, startDate, endDate) => `/ledger/statement?c=${clientId}&s=${startDate}&e=${endDate}`,
    getTransactionList: () => '/ledger/transactions',
    getStatementPdf: (clientId, startDate, endDate) =>
        `/ledger/statement/pdf?c=${clientId}&s=${startDate}&e=${endDate}`,
    saveStatementPdf: (clientId, startDate, endDate) =>
        `/ledger/statement/pdf?c=${clientId}&s=${startDate}&e=${endDate}`,
};

export const payments = {
    makePayment: () => '/payments',
    getSavedCardsForClient: (clientId) => `/payments/saved/clients/${clientId}`,
    updatePaymentMethodName: () => '/payments/saved/name',
    deletePaymentMethod: (paymentMethodId) => `/payments/saved/${paymentMethodId}`,
    saveCard: () => '/payments/saved',
    refund: () => '/payments/refunds',
    setAutopay: (paymentMethodId) => `/payments/saved/${paymentMethodId}/set-autopay`,
    removeAutopay: (paymentMethodId) => `/payments/saved/${paymentMethodId}/remove-autopay`,
    checkAutopay: (clientId) => `/payments/check-autopay?clientId=${clientId}`,
    batchInvoice: () => `/payments/batch-invoice`,
};

export const offices = {
    getOffices: () => '/offices',
};

export const rooms = {
    getRooms: () => `/rooms`,
    getRoomById: (id) => `/rooms/${id}`,
};

export const serviceItems = {
    adjustAmount: (serviceItemId) => `/service_items/${serviceItemId}`,
    deleteItem: (serviceItemId) => `/service_items/${serviceItemId}`,
    getServiceItemsByAppt: (apptId, clientId) => `/service_items/${apptId}/${clientId}`,
};

export const serviceFees = {
    getList: (level, levelId) => `/service_fees/list/${level}/${levelId}`,
};

export const clients = {
    getClientsList: () => `/clients/clientsList`,
    createClient: () => `/clients/create`,
    getInsurances: (id) => `/clients/${id}/insurances`,
    getContactDetailsByType: (clientId, contactType) => `/clients/${clientId}/${contactType}/details`,
    //updatePrimaryContact: (clientId) => `/clients/${clientId}/primary-contact`,
    updateContactDetailsByType: (clientId, contactType) => `/clients/${clientId}/${contactType}/details`,
    deleteContact: (contactId) => `/clients/contacts/${contactId}`,
    getClientGeneralInfo: (clientId) => `/clients/${clientId}/general-info`,
    getClientProfileInfo: (clientId) => `/clients/${clientId}/profile-info`,
    putClientGeneralInfo: (clientId) => `/clients/${clientId}/general-info`,
    putClientProfileInfo: (clientId) => `/clients/${clientId}/client-profile-info`,
    put_client_partial_info: (clientId) => `/clients/save-partial-info/${clientId}`,
    getClientReferralInfo: (clientId) => `/clients/${clientId}/referral-info`,
    putClientReferralInfo: (clientId) => `/clients/${clientId}/referral-info`,
    getClientMandateInfo: (clientId) => `/clients/${clientId}/mandate-info`,
    putClientMandateInfo: (clientId) => `/clients/${clientId}/mandate-info`,
    putAppointmentReminderSettings: (clientId) => `/clients/${clientId}/appointment-reminder-settings`,
    getAppointmentReminderSettings: (clientId) => `/clients/${clientId}/appointment-reminder-settings`,
    getCounselors: (id) => `/clients/${id}/counselors`,
    getServiceCodes: (id) => `/clients/${id}/service-codes`,
    getDiagnosticCodes: (id) => `/clients/${id}/diagnostic-codes`,
    getAttendanceHistory: (id) => `/clients/${id}/attendance-history`,
    getTags: (id) => `/clients/${id}/tags`,
    getAppointments: (id) => `/clients/${id}/appointments`,
    getClientFiles: (id) => `/clients/${id}/client-files`,
    getSharedFiles: (id) => `/clients/${id}/shared-files`,
    putAppointmentReminderSettingsByContactType: (clientId, contactType) =>
        `/clients/${clientId}/${contactType}/appointment-reminder-settings`,
    //getClients
    getClient: (id) => `clients/${id}/info`,
    getEmergencyDetails: (id) => `clients/${id}/emergency/details`,
    saveCounselors: (id) => `/clients/${id}/save-counselors`,
    saveMemo: (id) => `/clients/${id}/save-memo`,
    updateRecord: (id) => `/clients/${id}/update-record`,
    getClientsByIds: () => `/clients/clients-by-ids`,
    resetPortalPassword: () => `/clients/reset-portal-password`,
    inviteToPortal: () => '/clients/portal-invite',
    navigateToPortal: (clientId) => `/clients/portal-token?c=${clientId}`,
    getMedicalInfo: (clientId) => `/clients/${clientId}/medical-info`,
    putMedicalInfo: (clientId) => `/clients/${clientId}/medical-info`,
    getList: () => `/clients/`,
    getActiveClients: () => `/clients/active-clients`,
    getV2ActiveClients: () => '/clients/v2/active-clients',
    getClientsCount: () => '/clients/clients-count',
    getAllClientsCount: () => '/clients/allClientsCount',
    getClientsEmail: (dashboardId) => `/clients/get-clients-email/${dashboardId}`,
    updateDashboardUsed: (dashboard_id) => `/clients/update-dashboard-used/${dashboard_id}`,
    approveClients: () => `starkid/clients/changeActivateStatus`,
    getMoodHistory: (application, clientId) =>
        `${application === 'STAR Kids' ? 'starkid/' : ''}moodtracker/getmoodrecord/${
            application !== 'STAR Kids' ? 'mobex-health-hub/' : ''
        }${clientId}`,
};

export const appts = {
    deleteClientFromAppt: () => `/appts/delete-client`,
    updateAppt: (id) => `/appts/${id}`,
    updateApptGroup: (id) => `/appts/group/${id}`,
    deleteAppt: () => `appts/delete-appt`,
    deleteEvent: () => `events/remove-event`,
    getAppointment: (id) => `appts/${id}`,
    getList: () => `/appts/`,
    getAll: () => `appts/get-appts-for-user`,
    getMine: () => `appts/get-my-appts`,
    getClientAppts: () => '/appts/get-appts-for-client',
    getClientTags: () => '/appts/get-appts-by-tag',
    updateApptStatus: (apptId) => `/appts/${apptId}/appt_status`,
    googleOauthUrl: () => '/events/google-oauth/auth-code',
    googleOauthTokens: () => '/events/google-oauth/tokens',
    getGoogleEvents: () => 'events/google-oauth/events',
    deleteGoogleTokens: () => '/events/google-oauth/delete-tokens',
    batchUpdate: () => '/appts/batch',
    removeRecurrenceRule: () => '/appts/remove-recurrence',
    editRecurrenceRule: () => '/appts/edit-recurrence',
    addRecurrence: () => '/appts/add-recurrence',
    getByGroupUuid: (groupUuid) => `/appts/group/${groupUuid}`,
    getApptHistory: (clientId, currentDate) => `/appts/history/${clientId}?d=${currentDate}`,
};

export const events = {
    teleEvents: () => 'events/get-telehealth',
    updateEvent: () => 'events/update-event',
    deleteEventAdmin: () => 'events/admin-delete',
};

export const insurance = {
    saveInsuranceContactInformation: () => `/insurance/insurance-contact-info`,
    saveInsuranceDetailsInformation: () => `/insurance/insurance-details`,
    deleteInsurance: (insuranceId) => `/insurance/insurance-card/${insuranceId}`,
};

export const insurancePayers = {
    getList: () => `/insurance_payers/`,
};

export const chime = {
    createMeeting: (client_request_token) => `/chime/create-meeting/${client_request_token}`,
    deleteMeeting: (meeting_id) => `/chime/${meeting_id}`,
    createAttendee: (meeting_id, encoded_user_id) => `/chime/${meeting_id}/create-attendee/${encoded_user_id}`,
    getListOfAttendees: (meeting_id) => `/chime/${meeting_id}`,
    getAttendeeDetails: (meeting_id, attendee_id) => `/chime/${meeting_id}/${attendee_id}`,
    deleteAttendee: (meeting_id, attendee_id) => `/chime/${meeting_id}/${attendee_id}`,
    addToWaitList: (meeting_id, encoded_user_id) => `/chime/wait-list/${meeting_id}/${encoded_user_id}`,
    getWaitList: (meeting_id) => `/chime/wait-list/${meeting_id}`,
    checkAdmittanceOnWaitList: (meeting_id, encoded_user_id) => `/chime/wait-list/${meeting_id}/${encoded_user_id}`,
    removeFromWaitList: (meeting_id, encoded_user_id) => `/chime/wait-list/${meeting_id}/remove/${encoded_user_id}`,
    admitFromWaitList: (meeting_id, encoded_user_id) => `/chime/wait-list/${meeting_id}/${encoded_user_id}`,
    deleteWaitList: (meeting_id) => `/chime/wait-list/${meeting_id}`,
    kickAttendee: (meeting_id, attendee_id, encoded_user_id) =>
        `/chime/${meeting_id}/kick/${attendee_id}/${encoded_user_id}`,
};

export const progressNotes = {
    getProgressNote: (progress_id) => `/notes/${progress_id}`,
    saveDraft: (progress_id) => `notes/${progress_id}`,
    sign: (progress_id) => `notes/${progress_id}`,
    getHistory: (id) => `notes/client/${id}`,
};

export const notes = {
    getNotesByIds: () => `notes/forms-by-ids`,
    getChartNote: (id) => `notes/chart-note/${id}`,
    getNote: (id) => `notes/${id}`,
    saveNote: (id) => `notes/${id}`,
    signit: (id) => `/notes/sign-form/${id}`,
};

export const chartNote = {
    saveChartNote: () => ``,
};

export const serviceCodes = {
    getServiceCodes: () => `/services/service-codes`,
    getProductCodes: () => `/services/products`,
    getAllCodes: (groupByType, level, levelId, financialClass) =>
        groupByType
            ? `/services/all-codes?group=t&level=${level}&financialClass=${financialClass}&levelId=${levelId}`
            : `/services/all-codes?level=${level}&financialClass=${financialClass}&levelId=${levelId}`,
    getCancelNoShowServiceCodes: () => `/services/service-codes/cancel-no-show`,
};

export const settings = {
    getList: (level, level_id) => `/settings/list/${level}/${level_id}`,
    getSetting: (setting, level, level_id) => `/settings/setting/${setting}/${level}/${level_id}`,
    getSettingById: (id) => `/settings/${id}`,
    updateSettingById: (id) => `/settings/${id}`,
    createSettingData: (level, level_id, id) => `/settings/${level}/${level_id}/${id}`,
    connectStripe: () => `/settings/stripe/connect`,
    checkStripe: () => `/settings/stripe/check`,
    verifyStripe: (id) => `/settings/stripe/${id}`,
    updateMultipleSettings: () => `settings/update-multiple`,
};

export const toxicology = {
    getNewForm: (pageId) => `/form_data/pages_form_schema/${pageId}`,
    sign: (id) => `form_data/${id}`,
};

export const logs = {
    getList: () => `/logs/`,
    getActivityTimeline: () => `/logs/activity-timeline`,
};

export const documents = {
    getList: () => `/documents/`,
};

export const signatures = {
    getList: () => `/signatures/`,
    getSignature: (signatureId) => `/signatures/${signatureId}`,
    getSignatureById: () => `/signatures/get-by-id`,
    saveSignature: () => `/signatures/save`,
};

export const form_data = {
    signit: (id) => `/form_data/sign-form/${id}`,
    getTreatmentPlan: (clientId) => `/form_data/get_treatment_plan/${clientId}`,
    getToxReport: (id) => `/form_data/get_tox_report/${id}`,
    getList: () => `/form_data/`,
    getForm: (id) => `/form_data/${id}`,
};

export const contacts = {
    saveContact: (id) => `/contacts/${id}`, // id = 'new' || 0 for new contacts
};

export const apptItems = {
    getApptItems: () => `/appt_items/`,
    getApptItemsForGroupApptItems: () => `/appt_items/get-appt-items-for-group`,
};

export const emails = {
    getList: () => `/email/list`,
    getEmail: (id) => `/email/${id}`,
};

export const memory = {
    getNewMemory: () => `/alerts/memory`,
};

export const memberProfile = {
    createFunction: () => `/member_profile/profile-functions/create`,
    updateFunction: () => `/member_profile/profile-functions/update`,
    deleteFunction: (functionId) => `/member_profile/profile-functions/delete/${functionId}`,
    getDashboardCount: (id) => `/member_profile/profile-functions/getDashboardCount?id=${id}`,
    getListofUsers: (id) => `/member_profile/profile-functions/getEmails?id=${id}`,
    getFunction: (type, application, dashboardId) =>
        `/member_profile/profile-functions/list?type=${type}${application ? `&application=${application}` : ''}${
            dashboardId ? `&dashboardId=${dashboardId}` : ''
        }`,
    getSpecificFunction: (functionId, type) => `/member_profile/profile-functions/list?id=${functionId}&type=${type}`,
    uploadProfileFunctionsImage: () => `/member_profile/profile-functions/image`,
    createMemberProfile: () => `/member_profile/create`,
    updateMemberProfile: (memberProfileId) => `/member_profile/update/${memberProfileId}`,
    getMemberProfileDetail: (memberProfileId) => `/member_profile/details/${memberProfileId}`,
    getMemberProfileList: () => `/member_profile/list`,
    deleteMemberProfile: (memberProfileId) => `/member_profile/delete/${memberProfileId}`,
    getFunctionDetailsByIds: () => `/member_profile/dashboard-builder/get-function-details`,
    getSpecificGenericFunction: (generic_function, position, dashboard_id) =>
        `/generic-modules/getmoduledata/${dashboard_id}?generic_function=${generic_function}&position=${position}`,
    createGenericModule: () => `/generic-modules/module/create`,
    updateGenericModule: (id) => `/generic-modules/module/${id}`,
    deleteGenericModule: (id, generic_function, application, isMaster, dashboard_id) =>
        `/generic-modules/module/${id}?generic_function=${generic_function}&application=${application}&isMaster=${isMaster}&dashboard_id=${dashboard_id}`,
    createSubModule: () => `/generic-modules/submodule/create`,
    getSubModuleList: (moduleid) => `/generic-modules/submodulelist/${moduleid}`,
    getSubModule: (id) => `/generic-modules/submodule/${id}`,
    updateSubModule: (id) => `/generic-modules/submodule/${id}`,
    deleteSubModule: (id, isMaster, module_id) =>
        `/generic-modules/submodule/${id}?isMaster=${isMaster}&module_id=${module_id}`,
    createSubModuleLink: () => `/generic-modules/submodulelinks/create`,
    getSubModuleLinkList: (submoduleid) => `/generic-modules/submodulelinkslist/${submoduleid}`,
    getSubModuleLink: (id) => `/generic-modules/submodulelinks/${id}`,
    updateSubModuleLink: (id) => `/generic-modules/submodulelinks/${id}`,
    deleteSubModuleLink: (id, isMaster, module_id) =>
        `/generic-modules/submodulelinks/${id}?isMaster=${isMaster}&module_id=${module_id}`,
    deleteModuleAndSubModules: (dashboard_id) => `/generic-modules/moduleandsubmodule/${dashboard_id}`,
    getPositionData: (position, dashboard_id) => `/generic-modules/getposition/${dashboard_id}?position=${position}`,
    updateColor: (id) => `/generic-modules/updatecolor/${id}`,
    updateSvgColor: (company_id, dashboard_id) =>
        `/generic-modules/update-svg-color?company_id=${company_id}&dashboard_id=${dashboard_id}`,
    updateSubModuleSvgColor: (company_id) =>
        `/generic-modules/update-submodule-svgImage-color?company_id=${company_id}`,
    changeActiveStatus: () => `/generic-modules/changeactivestatus`,
    deleteUnSaved: () => `/generic-modules/deleteInActive`,
    reorderSubmodules: () => `/generic-modules/reorderSubmodules`,
    reorderSubmodulesLink: () => `/generic-modules/reorderSubmodulesLink`,
    getAvailableLanguageList: (id) => `/generic-modules/getallLanguages/${id}`,
    getAllLayerModuleData: (id, language) => `/generic-modules/getAllLayerModuleData/${id}?language=${language}`,
    addNewLanguage: () => `/generic-modules/addNewLanguage/create`,
    updateNewLanguage: () => `/generic-modules/addNewLanguage/update`,
    deleteLanguage: (id) => `/generic-modules/deleteLanguage/${id}`,
    getLookupTableByDashboardIds: () => `/generic-modules/getLookupTableIds`,
};

export const reminders = {
    createReminder: () => `/reminders`,
    updateReminder: (reminderId) => `/reminders/${reminderId}`,
    deleteReminder: (reminderId) => `/reminders/${reminderId}`,
    getReminder: () => `/reminders`,
};

export const apptLog = {
    createApptLog: () => `/appt-log`,
    updateApptLog: (sessionId) => `/appt-log/${sessionId}`,
    completeAppointment: (sessionId) => `/appt-log/complete/${sessionId}`,
};

export const dashboardBuilder = {
    getTemplateMeta: (template_id) => `/dashboard-builder/template-meta/${template_id}`,
    getAllTemplate: () => `/dashboard-builder/template-meta-list`,
    getTemplateList: (status) => `/dashboard-builder/template-list?status=${status}`,
    getAllTemplateList: (status) => `/dashboard-builder/template-list-all?status=${status}`,
    deleteTemplate: (template_id) => `/dashboard-builder/template/${template_id}`,
    createTemplate: () => `/dashboard-builder/template/create`,
    updateTemplate: (template_id) => `/dashboard-builder/template/${template_id}`,
    uploadAssets: () => `/dashboard-builder/upload-asset`,
    getAssets: (type, company_id) => `/dashboard-builder/assets?type=${type}&company_id=${company_id}`,
    getWidgets: () => `/dashboard-builder/get-widgets`,
    fetchDashboardData: (template_id) => `/dashboard-builder/template/${template_id}`,
    getUrls: () => `/dashboard-builder/get-urls`,
    getLastTemplateId: () => '/dashboard-builder/get-lasttemplate-id',
    assignDashboardToAll: (id) => `/dashboard-builder/template/assign_dashboard_to_all/${id}`,
    deleteAsset: (dashboardId, id) => `/dashboard-builder/asset/${dashboardId}/${id}`,
    getImageAsset: (id, type) => `/dashboard-builder/digitalSignage/${id}?type=${type}`,
    uploadImageAssets: (id, type, status) =>
        `dashboard-builder/upload-dashboard-builder-images/${id}/${status}?fileType=${type}`,
    changeActiveStatusofDigitalSignage: () => `dashboard-builder/changeactivestatus`,
    deleteActiveStatusofDigitalSignage: () => `dashboard-builder/deleteInActive`,
    copyDashboard: (id) => `dashboard-builder/copyDashboard/${id}`,
    isMasterExist: () => `dashboard-builder/isMasterExist`,
    getLanguagesList: () => `dashboard-builder/getLanguages`,
    checkDashboardName: () => `dashboard-builder/checkDashboardName`,
    updateRSS: () => `dashboard-builder/updateRss`,
    deleteRSS: (id) => `dashboard-builder/deleteRss/${id}`,
    getQRWithLogoByName: (fileName) => `dashboard-builder/getQRWithLogoByName?fileName=${fileName}`,
    getQRBuffer: (fileName) => `dashboard-builder/getQRBuffer?fileName=${fileName}`,
    generateQRWithLogo: () => `dashboard-builder/generateQRWithLogo`,
    generateQRWithoutLogo: () => `dashboard-builder/generateQRWithoutLogo`,
};

export const activityReminder = {
    createReminder: () => `/activity-reminders/create`,
    getReminder: (id) => `/activity-reminders/reminder/${id}`,
    deleteReminder: (id) => `/activity-reminders/delete/${id}`,
    updateReminder: () => `/activity-reminders/update`,
};

export const rssFeeds = {
    getRssList: () => `/rss_feeds_kiosk/feed-list`,
    createRssFeed: () => `/rss_feeds_kiosk/feeds/create`,
    getRssFeed: (id) => `/rss_feeds_kiosk/feeds/${id}`,
    updateRssFeed: (id) => `/rss_feeds_kiosk/feeds/${id}`,
    deleteRssFeed: (id) => `/rss_feeds_kiosk/feeds/${id}`,
    getListofUser: (id) => `/rss_feeds_kiosk/getListofUser/${id}`,
    getDashboardCount: (id) => `/rss_feeds_kiosk/getDashboardCount/${id}`,
    checkNameExists: () => `/rss_feeds_kiosk/checkRSSName`,
};

export const fileManager = {
    getDocumentList: () => '/file_manager/document-list',
    getDocumentDetails: (id) => `/file_manager/document/${id}`,
    createDocument: () => '/file_manager/create',
    updateDocument: (id) => `/file_manager/document/${id}`,
    deleteDocument: (id) => `/file_manager/document/${id}`,
    getDashboardCount: (id) => `/file_manager/getDashboardCount/${id}`,
    checkNameExists: () => `/file_manager/checkFileName`,
};

export const starkidEducation = {
    getEducation: (application) => `/${application}/education/getEducation`,
    getEducationById: (id, application) => `/${application}/education/getEducation/${id}`,
    addEducation: (application) => `/${application}/education/addEducation`,
    updateEducation: (id, application) => `/${application}/education/updateEducation/${id}`,
    deleteEducationResource: (id, application) => `/${application}/education/deleteEducationResource/${id}`,
    changepinstatus: (application) => `/${application}/education/changepinstatus`,
};

export const education = {
    getEducation: (application) => `/education/getEducation/${application}`,
    getEducationById: (id) => `/education/getEducationByDashboard/${id}`,
    addEducation: (application) => `/education/addEducation/${application}`,
    updateEducation: (id) => `/education/updateEducation/${id}`,
    deleteEducationResource: (id) => `/education/deleteEducationResource/${id}`,
    changepinstatus: () => `/education/changepinstatus`,
};

export const lookupTable = {
    getLookupTableById: (id) => `/lookup_table/lookup-table/${id}`,
    getAllLookupTable: () => `/lookup_table/lookup-table`,
    deleteLookupTable: (id) => `/lookup_table/lookup-table/${id}`,
    createLookupTable: () => `/lookup_table/lookup-table/create`,
    updateLookupTable: (id) => `/lookup_table/lookup-table/${id}`,
    getDashboardCount: (id) => `/lookup_table/getDashboardCount/${id}`,
    checkNameExits: () => `/lookup_table/checkLookupTableName`,
};

export const svgPicker = {
    getSvgList: () => `/svg_picker/getSvgList`,
    addSvg: () => `/svg_picker/Svg`,
    deleteSvg: (id) => `/svg_picker/Svg/${id}`,
};

export const starkidsDashboard = {
    getSignupField: (id) => `/starkids_dashboards/getSignupField/${id}`,
    getSlide: (id) => `/starkids_dashboards/getSlides/${id}`,
    deleteSlides: () => `/starkids_dashboards/deleteSlide`,
};

export const trackMilestones = {
    getMilestonesByDashboardId: (id, module_id) => `/milestones/getmilestonebyDashboardId/${id}?module_id=${module_id}`,
    deleteMilestonesByDashboardId: (id) => `/milestones/deletemilestonesbydashboard/${id}`,
    createMilestone: () => `/milestones/createmilestone`,
    updateMilestone: (id) => `/milestones/updatemilestone/${id}`,
    deleteMilestone: (id) => `/milestones/deletemilestone/${id}`,
    getSubMilestones: (id) => `/submilestones/getsubmilestones/${id}`,
    createSubMilestone: () => `/submilestones/createsubmilestone`,
    updateSubMilestone: (id) => `/submilestones/updatesubmilestone/${id}`,
    deleteSubMilestone: (id) => `/submilestones/deletesubmilestone/${id}`,
    updatePriorityOfSubMilestone: () => `/submilestones/updatepriority`,
};

export const card_colors ={
    getCardColor:  (companyid) => `/card_colors/getColor/${companyid}`,
    createCardColor:(companyid)=>`/card_colors/createColor/${companyid}`,
    updateCardColor:(companyid,id)=>`/card_colors/updateColor/${companyid}/${id}`
}
export const coreFunctions = {
    getModuleDetails: (id) => `/core_functions/module/${id}`,
    createModule: () => `/core_functions/module/create`,
    updateModule: (id) => `/core_functions/module/${id}`,
    deleteModule: (id) => `/core_functions/module/${id}`,
};
